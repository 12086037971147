import { mapActions, mapGetters } from "vuex"
import ThemisDecision from "@/components/shared/decision"
import {
  DATA_EXPORT_POLL_INTERVAL,
  DATA_EXPORT_STATUS,
  DATA_EXPORT_TYPE,
  NULL
} from "@/constants"

export default {
  name      : "Analytics",
  components: {
    ThemisDecision
  },
  data() {
    return {
      showAnalyticsExportDownloadDialog: false,
      exportStatusPolling              : NULL
    }
  },

  beforeDestroy() {
    clearInterval(this.exportStatusPolling)
  },

  computed: {
    ...mapGetters({
      isAddingExport       : "exports/isAddingExport",
      exports              : "exports/exports",
      isExportAdded        : "exports/isExportAdded",
      latestAnalyticsExport: "exports/latestAnalyticsExport"
    }),

    latestAnalyticsExportStatus() {
      return this.latestAnalyticsExport?.status
    },
    isAnalyticsExportInProgress() {
      return this.isAddingExport || this.isAnalyticsExportInitiated
    },

    isAnalyticsExportComplete() {
      return !this.isAddingExport && this.latestAnalyticsExportStatus === DATA_EXPORT_STATUS.COMPLETED
    },

    isAnalyticsExportInitiated() {
      return this.latestAnalyticsExportStatus === DATA_EXPORT_STATUS.INITIATED
    },

    analyticsExportCreatedAt() {
      return this.latestAnalyticsExport && this.$moment(this.latestAnalyticsExport.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)")
    }
  },
  methods: {
    ...mapActions({
      addExport         : "exports/addExport",
      loadExport        : "exports/loadExport",
      downloadDataExport: "exports/downloadDataExport"
    }),

    exportAnalytics() {
      this.addExport({
        type: DATA_EXPORT_TYPE.ANALYTICS
      })
    },

    handleAnalyticsExportDownload() {
      this.downloadDataExport(this.latestAnalyticsExport)
      this.showAnalyticsExportDownloadDialog = false
    },

    clearExportStatusPollingInterval() {
      if (this.exportStatusPolling) {
        clearInterval(this.exportStatusPolling)
        this.exportStatusPolling = NULL
      }
    }
  },
  watch: {
    latestAnalyticsExportStatus: {
      handler: function(newValue) {
        if (newValue) {
          if (newValue === DATA_EXPORT_STATUS.INITIATED) {
            this.exportStatusPolling = setInterval(() =>
              this.loadExport(this.latestAnalyticsExport.id),
            DATA_EXPORT_POLL_INTERVAL)
          }

          if (newValue === DATA_EXPORT_STATUS.COMPLETED || newValue === DATA_EXPORT_STATUS.FAILED) {
            this.clearExportStatusPollingInterval()
          }
        }
      }
    }
  }
}
